import React from "react"
import theme from "components/layout/theme"

const Heading: React.FC<{ text?: string }> = ({ text }) => {
  if (!text) return null
  return (
    <p
      className="bg-gray-100 text-2xl md:text-2xl lg:text-3xl pl-4 py-1 text-site-blue-dark"
      style={{
        borderLeftWidth: "8px",
        borderLeftColor: theme.colors.blue,
      }}
    >
      {text}
    </p>
  )
}

export default Heading
