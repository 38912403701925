import React from "react"

import { PinkAppButton, SecondaryAppButton } from "components/buttons"

interface iQPLSelectButton {
  selected: boolean
  [id: string]: any
}

const QPLTabSelectButton: React.FC<iQPLSelectButton> = ({
  selected,
  ...rest
}) => {
  if (selected) return <PinkAppButton {...rest} />
  return <SecondaryAppButton {...rest} />
}

export default QPLTabSelectButton
