import React from "react"

import Layout from "components/layout"
import QPLPage from "components/QPLPage"
import SEO from "components/seo"

const QPL = () => {
  return (
    <Layout>
      <SEO title="Questions you can ask your doctor" />
      {/* @ts-ignore */}
      <QPLPage />
    </Layout>
  )
}

export default QPL
