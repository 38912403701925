import React from "react"

import { ContentSeparator } from "../Utils/ContentSeparator"
import QuestionsList from "./QuestionsList"
import { QPLNode, QPLQuestion } from "api/types"

interface iSeeAllQuestions {
  content: QPLNode[] | null
  selectedQuestions: QPLQuestion[]
  questionsWaitingList: QPLQuestion[]
  show: boolean
  onClickQuestion?: (question: QPLQuestion) => void
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const SeeAllQuestionsTab: React.FC<iSeeAllQuestions> = ({
  content,
  selectedQuestions,
  show,
  onClickQuestion,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  if (!show) return null
  return (
    <section>
      <ContentSeparator />
      <ContentSeparator />
      <QuestionsList
        onClickNote={onClickNote}
        questionsWaitingList={questionsWaitingList}
        content={content}
        selectedQuestions={selectedQuestions}
        onClickQuestion={onClickQuestion}
        qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
      />
    </section>
  )
}

export default SeeAllQuestionsTab
