import { graphql, StaticQuery } from "gatsby"
import React from "react"

import { QPLNode } from "api/types"

interface iQPLContentWrapper {
  children: (content: QPLNode[]) => void
}

const QPLContentWrapper: React.FC<iQPLContentWrapper> = ({ children }) => {
  const qplContentQuery = graphql`
    query {
      allQplYaml {
        nodes {
          id: categoryId
          categoryName
          questions {
            id: questionId
            qplCategoryId
            readMoreLink
            questionText
          }
        }
      }
    }
  `
  return (
    <StaticQuery query={qplContentQuery}>
      {data => {
        return <>{children(data.allQplYaml.nodes)}</>
      }}
    </StaticQuery>
  )
}

export default QPLContentWrapper
