import React from "react"

import { FaSearch } from "react-icons/fa"
import { GreenAppButton } from "components/buttons"
import Form from "components/form/Form"
import TextInput from "components/form/TextInput"

interface iSearchBar {
  onChangeSearchInput: (searchPhrase: string | null) => void
}

const SearchBar: React.FC<iSearchBar> = ({ onChangeSearchInput }) => {
  return (
    <div>
      <Form
        data={{ search: "" }}
        onSubmit={data => {
          onChangeSearchInput(data.search)
        }}
        onChange={data => {
          onChangeSearchInput(data.search)
        }}
      >
        {(data, setData) => (
          <>
            <div className="items-center justify-center hidden sm:flex w-full">
              <div style={{ width: "396px" }}>
                <TextInput
                  label={""}
                  onChange={e => setData("search", e.target.value)}
                  placeholder={"Enter a question"}
                  value={data.search}
                  type={"text"}
                  error={false}
                  disable={false}
                />
              </div>
            </div>
            <div className="block sm:hidden">
              <TextInput
                label={""}
                onChange={e => setData("search", e.target.value)}
                placeholder={"Search a question"}
                value={data.search}
                type={"text"}
                error={false}
                disable={false}
              />
            </div>
            <div className="flex items-center justify-center mt-3">
              <GreenAppButton
                type={"submit"}
                title={"Search questions"}
                Icon={<FaSearch />}
              />
            </div>
          </>
        )}
      </Form>
    </div>
  )
}

export default SearchBar
