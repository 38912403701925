import React from "react"

import { QPLNode, QPLQuestion } from "api/types"
import Heading from "./Heading"
import QuestionCard from "./QuestionCard"

interface iQPLTopic {
  category: QPLNode
  onClickQuestion?: (question: QPLQuestion) => void
  selectedQuestions: QPLQuestion[]
  useDeleteButton?: boolean
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const QPLTopic: React.FC<iQPLTopic> = ({
  category,
  onClickQuestion,
  selectedQuestions,
  useDeleteButton,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  return (
    <div>
      <Heading text={category?.categoryName} />
      <div className="grid grid-cols-1 gap-6 my-4">
        {category?.questions &&
          category.questions.map((item, index) => (
            <QuestionCard
              unsavedChanges={qplNotesList_unsavedChanges[item.id]}
              onClickNote={onClickNote}
              question={item}
              selected={
                selectedQuestions.filter(
                  que => que.questionText === item.questionText
                ).length > 0
              }
              key={index}
              onClick={onClickQuestion}
              useDeleteButton={useDeleteButton}
              waiting={
                questionsWaitingList.filter(
                  que => que.questionText === item.questionText
                ).length > 0
              }
            />
          ))}
      </div>
    </div>
  )
}

export default QPLTopic
