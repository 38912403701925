import React from "react"

import { FaExclamationTriangle } from "react-icons/fa"
import { ContentSeparator } from "../Utils/ContentSeparator"
import QuestionsList from "./QuestionsList"
import { QPLNode, QPLQuestion } from "api/types"
import shortid from "shortid"

interface iMyQuestions {
  show: boolean
  content: QPLNode[] | null
  selectedQuestions: QPLQuestion[]
  onClickQuestion?: (question: QPLQuestion) => void
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const MyQuestionsView: React.FC<{
  content: QPLNode[] | null
  selectedQuestions: QPLQuestion[]
  onClickQuestion?: (question: QPLQuestion) => void
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}> = ({
  content,
  selectedQuestions,
  onClickQuestion,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  return (
    <section>
      <div className="hidden sm:block">
        <ContentSeparator />
      </div>
      <ContentSeparator />
      <ContentSeparator />
      {Boolean(selectedQuestions.length > 0) && (
        <QuestionsList
          content={content}
          selectedQuestions={selectedQuestions}
          onClickQuestion={onClickQuestion}
          useDeleteButton={true}
          questionsWaitingList={questionsWaitingList}
          onClickNote={onClickNote}
          qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
        />
      )}
      {Boolean(selectedQuestions.length <= 0) && (
        <div className="flex items-center justify-center">
          <p className="flex items-center border bg-white text-gray-600 px-4 py-2 rounded-full">
            <span className="mr-2">
              <FaExclamationTriangle />
            </span>
            <span>No question selected</span>
          </p>
        </div>
      )}
    </section>
  )
}

const MyQuestions: React.FC<iMyQuestions> = ({
  show,
  selectedQuestions,
  onClickQuestion,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  if (!show) return null
  return (
    <MyQuestionsView
      qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
      onClickNote={onClickNote}
      content={[
        {
          categoryName: "My Questions",
          id: shortid.generate(),
          questions: selectedQuestions,
        },
      ]}
      selectedQuestions={selectedQuestions}
      onClickQuestion={onClickQuestion}
      questionsWaitingList={questionsWaitingList}
    />
  )
}

export default MyQuestions
