import FuzzySearch from "fuzzy-search"
import React, { useState } from "react"

import { FaExclamationTriangle } from "react-icons/fa"
import { ContentSeparator } from "../../Utils/ContentSeparator"
import QuestionsList from "../QuestionsList"
import { QPLNode, QPLQuestion } from "api/types"
import { extractQuestionsFromQPLNodes } from "../utils"
import SearchBar from "./SearchBar"
import shortid from "shortid"

interface iQuestionsSearchView {
  allQuestions: QPLQuestion[] | null
  selectedQuestions: QPLQuestion[]
  onClickQuestion?: (question: QPLQuestion) => void
  questionsWaitingList: QPLQuestion[]
  onChangeSearchInput: (searchPhrase: string | null) => void
  searchPhrase: string | null
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

interface iQuestionsSearch {
  show: boolean
  content: QPLNode[] | null
  selectedQuestions: QPLQuestion[]
  onClickQuestion?: (question: QPLQuestion) => void
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const QuestionsSearchView: React.FC<iQuestionsSearchView> = ({
  onChangeSearchInput,
  allQuestions,
  selectedQuestions,
  onClickQuestion,
  questionsWaitingList,
  searchPhrase,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  return (
    <>
      <ContentSeparator />
      <ContentSeparator />
      <SearchBar onChangeSearchInput={onChangeSearchInput} />
      <ContentSeparator />
      <ContentSeparator />
      {Boolean(allQuestions?.length) && (
        <QuestionsList
          onClickNote={onClickNote}
          content={[
            {
              categoryName: `Search results "${searchPhrase}"`,
              id: shortid.generate(),
              questions: allQuestions,
            },
          ]}
          selectedQuestions={selectedQuestions}
          questionsWaitingList={questionsWaitingList}
          onClickQuestion={onClickQuestion}
          qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
        />
      )}
      {!allQuestions?.length && (
        <div className="pt-20 pb-32 flex items-center justify-center">
          <p className="flex items-center text-gray-500 px-4 py-2 rounded-full">
            <span className="mr-2">
              <FaExclamationTriangle />
            </span>
            {!searchPhrase && <span>No question searched</span>}
            {searchPhrase && <span>No question found</span>}
          </p>
        </div>
      )}
    </>
  )
}

const QuestionsSearch: React.FC<iQuestionsSearch> = ({
  show,
  content,
  selectedQuestions,
  onClickQuestion,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  if (!show) return null
  const [searchQuestions, setSearchQuestions] = useState<QPLQuestion[]>([])
  const [searchPhrase, setSearchPhrase] = useState<string | null>("")
  const allQuestions = extractQuestionsFromQPLNodes(content)
  const searcher = new FuzzySearch(allQuestions, ["questionText"])
  const onChangeSearchInput = (searchPhrase: string | null) => {
    setSearchPhrase(searchPhrase)
    if (!searchPhrase) setSearchQuestions([])
    else setSearchQuestions(searcher.search(searchPhrase))
  }
  return (
    <QuestionsSearchView
      qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
      onClickNote={onClickNote}
      allQuestions={searchQuestions}
      onClickQuestion={onClickQuestion}
      selectedQuestions={selectedQuestions}
      questionsWaitingList={questionsWaitingList}
      onChangeSearchInput={onChangeSearchInput}
      searchPhrase={searchPhrase}
    />
  )
}

export default QuestionsSearch
