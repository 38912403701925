import { graphql, StaticQuery } from "gatsby"
import Img from "gatsby-image"
import React from "react"

import { FaList, FaUserAlt } from "react-icons/fa"
import SiteContainer from "../SiteContainer"
import { ContentSeparator } from "../Utils/ContentSeparator"
import MyQuestions from "./MyQuestions"
import QPLTabSelectButton from "./QPLTabSelectButton"
import QuestionsSearch from "./QuestionsSearch"
import SearchSelectTabFAB from "./SearchSelectTabFAB"
import SeeAllQuestionsTab from "./SeeAllQuestionsTab"
import { QPLNode, QPLQuestion } from "api/types"

export type selectedViewType = "myQuestions" | "allQuestions" | "search"

export interface iQPLPageView {
  content: QPLNode[] | null
  selectedView: selectedViewType
  onClickSeeAllQuestions: () => void
  onClickMyQuestions: () => void
  onClickSearchFAB: () => void
  onClickQuestion?: (question: QPLQuestion) => void
  selectedQuestions: QPLQuestion[]
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const gql_queryHeroImage = graphql`
  query {
    file(relativePath: { eq: "qplBanner.png" }) {
      childImageSharp {
        fluid(maxWidth: 1200) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const QPLPageView: React.FC<iQPLPageView> = ({
  content,
  selectedView,
  onClickSeeAllQuestions,
  onClickMyQuestions,
  onClickQuestion,
  selectedQuestions,
  questionsWaitingList,
  onClickSearchFAB,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  return (
    <>
      <StaticQuery query={gql_queryHeroImage}>
        {data => (
          <Img
            fluid={data.file.childImageSharp.fluid}
            style={{ maxHeight: "256px" }}
          />
        )}
      </StaticQuery>
      <SiteContainer className="py-8">
        <section>
          <h1 className="text-3xl  md:text-4xl lg:text-5xl text-site-blue-dark">
            Your questions answered and questions to ask your doctor
          </h1>
          <ContentSeparator />
          <p className="text-site-blue-dark">
            Below you will see a list of questions that you could ask your
            doctor or health professional. Some of the questions may not apply
            to you as early menopause affects every woman in different ways. The
            questions you have selected will be displayed in "My questions" and
            on your dashboard. This should make it easier to find them during
            your appointment with your health professional.
          </p>
          <p className="text-site-blue-dark">
            You can take notes during or after your appointment using the note
            icon. This may help you remember what has been discussed during your
            appointment.
          </p>
          <ContentSeparator />
          <div className="flex items-center flex-wrap">
            <div className="mr-2 mb-2 sm:mb-0">
              <QPLTabSelectButton
                selected={selectedView === "allQuestions"}
                title="See all questions"
                Icon={<FaList />}
                onClick={onClickSeeAllQuestions}
              />
            </div>
            {Boolean(selectedQuestions.length) && (
              <div className="mb-2 sm:mb-0">
                <QPLTabSelectButton
                  selected={selectedView === "myQuestions"}
                  title="My questions"
                  Icon={<FaUserAlt />}
                  onClick={onClickMyQuestions}
                />
              </div>
            )}
          </div>
        </section>
        <SeeAllQuestionsTab
          content={content}
          selectedQuestions={selectedQuestions}
          show={selectedView === "allQuestions"}
          onClickQuestion={onClickQuestion}
          questionsWaitingList={questionsWaitingList}
          onClickNote={onClickNote}
          qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
        />
        <MyQuestions
          qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
          show={selectedView === "myQuestions"}
          content={content}
          selectedQuestions={selectedQuestions}
          onClickQuestion={onClickQuestion}
          questionsWaitingList={questionsWaitingList}
          onClickNote={onClickNote}
        />
        <QuestionsSearch
          show={selectedView === "search"}
          content={content}
          selectedQuestions={selectedQuestions}
          questionsWaitingList={questionsWaitingList}
          onClickQuestion={onClickQuestion}
          onClickNote={onClickNote}
          qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
        />
      </SiteContainer>
      <SearchSelectTabFAB
        selected={selectedView === "search"}
        onClick={onClickSearchFAB}
      />
    </>
  )
}

export default QPLPageView
