import React from "react"

import { QPLNode, QPLQuestion } from "api/types"
import QPLTopic from "./QPLTopic"

interface iQuestionsList {
  content: QPLNode[] | null
  onClickQuestion?: (question: QPLQuestion) => void
  selectedQuestions: QPLQuestion[]
  useDeleteButton?: boolean
  questionsWaitingList: QPLQuestion[]
  onClickNote: (question: QPLQuestion) => void
  qplNotesList_unsavedChanges: { [id: string]: boolean }
}

const QuestionsList: React.FC<iQuestionsList> = ({
  content,
  onClickQuestion,
  selectedQuestions,
  useDeleteButton,
  questionsWaitingList,
  onClickNote,
  qplNotesList_unsavedChanges,
}) => {
  return (
    <>
      {content && content.length
        ? content.map((item, index) => (
            <QPLTopic
              qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
              selectedQuestions={selectedQuestions}
              category={item}
              key={index}
              onClickQuestion={onClickQuestion}
              useDeleteButton={useDeleteButton}
              questionsWaitingList={questionsWaitingList}
              onClickNote={onClickNote}
            />
          ))
        : null}
    </>
  )
}

export default QuestionsList
