import React from "react"

interface iFAB {
  onClick: () => void
  bgColor?: string
  textColor?: string
  Icon: any
  title?: string
}

const FAB: React.FC<iFAB> = ({ onClick, bgColor, textColor, Icon, title }) => {
  return (
    <button
      title={title}
      onClick={onClick}
      className={`p-4 ${bgColor} ${
        textColor || "text-white"
      } flex items-center justify-center text-xl sm:text-base
                  shadow-lg  transition duration-100 rounded-full
                 `}
    >
      {Icon}
    </button>
  )
}

export default FAB
