import React from "react"

import { FaSearch } from "react-icons/fa"
import FAB from "../FAB"

interface iSearchSelectTabFAB {
  selected: boolean
  onClick: () => void
}

const SearchSelectTabFAB: React.FC<iSearchSelectTabFAB> = ({
  selected,
  onClick,
}) => {
  return (
    <div className="fixed bottom-0 right-0 pb-4 pr-4 leading-none">
      <FAB
        onClick={onClick}
        Icon={<FaSearch />}
        title="Search"
        bgColor={`
        ${!selected && "bg-site-blue-dark button-bg-site-blue-dark"}
        ${selected && "bg-site-green button-bg-site-green"}
      `}
      />
    </div>
  )
}

export default SearchSelectTabFAB
