import { Amplify } from "aws-amplify"
import { AWS_CONFIG } from "../../../aws-config"
import React, { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import smoothscroll from "smoothscroll-polyfill"
import QPLContentWrapper from "./QPLContentWrapper"
import { apiPostQplDataz } from "api/queries"
import toastError from "utils/toastError"
import syncProfileWithSessionStorage from "components/QueryUserProfile/syncProfileWithSessionStorage"
import { UserType, QPLQuestion, QplDataz } from "api/types"

import QPLNote from "./QPLNote"
import QPLPageView, { selectedViewType } from "./QPLPageView"
import { extractQuestionsFromQPLNodes, idsListToQPLQuestion } from "./utils"

const QPLPage: React.FC<{ loggedInUser: UserType | null }> = ({
  loggedInUser,
}) => {
  const [selectedView, setSelectedView] = useState<selectedViewType>(
    "allQuestions"
  )
  const [selectedQuestionsList, setSelectedQuestionsList] = useState<
    QplDataz[] | null
  >(null)
  const [selectedQuestionsId, setSelectedQuestionsId] = useState<string[]>([])
  const [questionsInterimList, setQuestionsInterimList] = useState<string[]>([])
  const [userProfileState, setUserProfileState] = useState<UserType>(null)
  const [openQPLNote, setOpenQPLNote] = useState(false)
  const [
    qplNoteSelectedQuestion,
    setQPLNoteSelectedQuestion,
  ] = useState<QPLQuestion | null>(null)

  const [
    qplNotesList_deleteLoading,
    set_qplNotesList_deleteLoading,
  ] = useState<{ [id: string]: boolean }>({})
  const [
    qplNotesList_unsavedChanges,
    set_qplNotesList_unsavedChanges,
  ] = useState<{ [id: string]: boolean }>({})
  const [
    qplNotesList_updateLoading,
    set_qplNotesList_updateLoading,
  ] = useState<{ [id: string]: boolean }>({})

  useEffect(() => {
    Amplify.configure(AWS_CONFIG)
  }, [])

  useEffect(() => {
    smoothscroll.polyfill()
    setUserProfileState(loggedInUser)
    if (
      !loggedInUser ||
      !loggedInUser.qplDataz ||
      !loggedInUser.qplDataz.length
    ) {
      return
    }
    const _data = loggedInUser.qplDataz.filter(
      item => item && item.selectedValue
    )
    const selectedIdList = _data.length
      ? _data.map(item => item!.id).filter(Boolean)
      : []

    if (_data) {
      setSelectedQuestionsList(_data as QplDataz[])
      setSelectedQuestionsId(selectedIdList as string[])
      setQuestionsInterimList(selectedIdList as string[])
    }
  }, [loggedInUser])

  const onClickQuestion = (question: QPLQuestion) => {
    if (
      getWaitingList(questionsInterimList, selectedQuestionsId).filter(
        item => item === question.id
      ).length > 0
    ) {
      return
    }

    const _list = questionsInterimList.filter(item => item !== question.id)
    if (questionsInterimList.length === _list.length)
      setQuestionsInterimList([...questionsInterimList, question.id])
    else setQuestionsInterimList(_list)
  }

  const syncData = async (questionsToBeSynced: (string | null)[]) => {
    if (!userProfileState) return
    try {
      const parseSelectedQuestions = questionsToBeSynced
        .filter(Boolean)
        .map(item => ({
          id: item as string,
          selectedValue: true,
          notes: selectedQuestionsList?.find(o => o.id === item)?.notes || "",
        }))
      const result = await apiPostQplDataz(parseSelectedQuestions)
      const selectedQPLQuestions = result.qplDataz
        ?.filter(Boolean)
        .map(item => item?.id as string)
      setSelectedQuestionsId(selectedQPLQuestions || [])
      setSelectedQuestionsList(result.qplDataz as QplDataz[])
      setUserProfileState(result as UserType)
      syncProfileWithSessionStorage({
        ...loggedInUser,
        ...(result as UserType),
      })
    } catch (e) {
      console.error(e)
      toastError(toast, "Something went wrong. Reload the page.", "bottom-left")
    }
  }

  useEffect(() => {
    syncData(questionsInterimList)
  }, [questionsInterimList])

  const getWaitingList = (A: (string | null)[], B: (string | null)[]) => {
    return A.filter(x => B.filter(y => x === y).length === 0).concat(
      B.filter(x => A.filter(y => x === y).length === 0)
    )
  }

  useEffect(() => {
    setOpenQPLNote(true)
  }, [qplNoteSelectedQuestion])

  const onClickNote = (question: QPLQuestion) => {
    if (qplNoteSelectedQuestion && question.id === qplNoteSelectedQuestion.id)
      setOpenQPLNote(true)
    else setQPLNoteSelectedQuestion(question)
  }

  return (
    <>
      <QPLNote
        userProfile={userProfileState}
        question={qplNoteSelectedQuestion}
        sidebarOpen={openQPLNote}
        onToggleSidebar={(open: boolean) => setOpenQPLNote(open)}
        onChangeDeleteLoading={list => set_qplNotesList_deleteLoading(list)}
        onChangeUnsavedNotes={list => set_qplNotesList_unsavedChanges(list)}
        onChangeUpdateLoading={list => set_qplNotesList_updateLoading(list)}
      />
      <QPLContentWrapper>
        {content => {
          const extractedAllQuestion = extractQuestionsFromQPLNodes(content)
          return (
            <QPLPageView
              qplNotesList_unsavedChanges={qplNotesList_unsavedChanges}
              onClickNote={onClickNote}
              content={content}
              selectedView={selectedView}
              onClickMyQuestions={() => setSelectedView("myQuestions")}
              onClickSeeAllQuestions={() => setSelectedView("allQuestions")}
              onClickSearchFAB={() => {
                setSelectedView("search")
                if (typeof window !== `undefined`) {
                  window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: "smooth",
                  })
                }
              }}
              onClickQuestion={onClickQuestion}
              selectedQuestions={idsListToQPLQuestion(
                extractedAllQuestion,
                selectedQuestionsId
              )}
              questionsWaitingList={idsListToQPLQuestion(
                extractedAllQuestion,
                getWaitingList(questionsInterimList, selectedQuestionsId)
              )}
            />
          )
        }}
      </QPLContentWrapper>
      <ToastContainer />
    </>
  )
}

export default QPLPage
