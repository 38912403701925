import React from "react"
import { PuffLoader } from "react-spinners"
import Switch from "react-switch"

import { FaRegStickyNote, FaTrashAlt } from "react-icons/fa"
import { RedAppButton } from "components/buttons"
import { QPLQuestion } from "api/types"
//@ts-ignore
import RelatedArticles from "./RelatedArticles"

interface iQuestionCard {
  question: QPLQuestion
  selected: boolean
  useDeleteButton?: boolean
  onClick?: (question: QPLQuestion) => void
  waiting: boolean
  onClickNote: (question: QPLQuestion) => void
  unsavedChanges: boolean
}

const TakeNotesButton: React.FC<{
  onClick: () => void
  unsavedChanges: boolean
}> = ({ onClick, unsavedChanges }) => {
  return (
    <button
      className="relative bg-site-blue button-bg-site-blue text-white px-3 py-2 text-sm rounded-full leading-none"
      onClick={onClick}
      title="Take notes"
    >
      <FaRegStickyNote className="inline" />
      {unsavedChanges && (
        <div
          className="top-0 right-0 absolute bg-yellow-500 rounded-full"
          style={{ height: "12px", width: "12px" }}
        />
      )}
    </button>
  )
}

const MobileQuestionCard: React.FC<{
  question: QPLQuestion
  selected: boolean
  useDeleteButton?: boolean
  onClick: () => void
  waiting: boolean
  onClickNote: (question: QPLQuestion) => void
  unsavedChanges: boolean
}> = ({
  question,
  selected,
  useDeleteButton,
  onClick,
  waiting,
  onClickNote,
  unsavedChanges,
}) => {
  return (
    <div className="bg-blue-100 border site-border-radius text-lg flex lg:hidden flex-col justify-between">
      <div className="px-4 py-3">
        <p className="text-site-blue-dark">{question.questionText}</p>
      </div>
      <div className="px-4 py-3 border-t flex items-center justify-between">
        <div className="mr-2 flex items-center">
          {!waiting && selected && (
            <TakeNotesButton
              onClick={() => onClickNote(question)}
              unsavedChanges={unsavedChanges}
            />
          )}
          <div className={`${!waiting && selected && "ml-2"}`}>
            <RelatedArticles articles={question.readMoreLink} />
          </div>
        </div>

        <div className="flex items-center">
          {waiting && (
            <div className="mr-2">
              <PuffLoader size={16} color={"#003b78"} loading={true} />
            </div>
          )}
          {!useDeleteButton && (
            <Switch
              className="react-switch"
              onChange={onClick}
              checked={selected}
              aria-label="super secret label that is not visible"
            />
          )}
          {useDeleteButton && (
            <RedAppButton Icon={<FaTrashAlt />} onClick={onClick} />
          )}
        </div>
      </div>
    </div>
  )
}

const DesktopQuestionCard: React.FC<{
  question: QPLQuestion
  selected: boolean
  useDeleteButton?: boolean
  onClick: () => void
  waiting: boolean
  onClickNote: (question: QPLQuestion) => void
  unsavedChanges: boolean
}> = ({
  question,
  selected,
  useDeleteButton,
  onClick,
  waiting,
  onClickNote,
  unsavedChanges,
}) => {
  return (
    <div className="hidden px-4 py-3 justify-between bg-gray-100 border site-border-radius text-lg lg:flex items-center">
      <div className="w-4/6">
        <p className="text-site-blue-dark">{question.questionText}</p>
      </div>
      <div className="flex items-center justify-end">
        {!waiting && selected && (
          <div className="mr-2">
            <TakeNotesButton
              onClick={() => onClickNote(question)}
              unsavedChanges={unsavedChanges}
            />
          </div>
        )}
        {question.readMoreLink && (
          <div className="mr-2">
            <RelatedArticles articles={question.readMoreLink} />
          </div>
        )}
        <div className="flex items-center">
          {waiting && (
            <div className="mr-2">
              <PuffLoader size={16} color={"#003b78"} loading={true} />
            </div>
          )}
          {!useDeleteButton && (
            <Switch
              className="react-switch"
              onChange={onClick}
              checked={selected}
              aria-label="super secret label that is not visible"
            />
          )}
          {useDeleteButton && (
            <RedAppButton Icon={<FaTrashAlt />} onClick={onClick} />
          )}
        </div>
      </div>
    </div>
  )
}

const QuestionCard: React.FC<iQuestionCard> = ({
  question,
  selected,
  onClick,
  useDeleteButton,
  waiting,
  onClickNote,
  unsavedChanges,
}) => {
  const onClickQuestion = () => {
    if (onClick) onClick(question)
  }
  return (
    <>
      <MobileQuestionCard
        unsavedChanges={unsavedChanges}
        question={question}
        selected={selected}
        onClick={onClickQuestion}
        waiting={waiting}
        useDeleteButton={useDeleteButton}
        onClickNote={onClickNote}
      />
      <DesktopQuestionCard
        unsavedChanges={unsavedChanges}
        question={question}
        selected={selected}
        onClick={onClickQuestion}
        waiting={waiting}
        useDeleteButton={useDeleteButton}
        onClickNote={onClickNote}
      />
    </>
  )
}

export default QuestionCard
