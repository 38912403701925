import { Link } from "gatsby"
import React from "react"

import { FaBook } from "react-icons/fa"

interface iRelatedArticles {
  articles: (string | null)[]
}

const RelatedArticles: React.FC<iRelatedArticles> = ({ articles }) => {
  if (!articles.length) return null
  return (
    <div className="flex">
      <Link to={`${articles}`} className="no-underline">
        <button className="button-bg-site-pink rounded-full px-4 py-2 text-sm text-white flex items-center leading-none no-border">
          <span className="mr-2">
            <FaBook />
          </span>
          <span>Read more</span>
        </button>
      </Link>
    </div>
  )
}

export default RelatedArticles
